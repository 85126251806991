.centeredCard {
    background-color: 	#333333;
    border-radius: 12px;
    text-align: center;
    line-height: 30px;
  }

.centeredCard h1 {
  color:#ffffff;
}

.centeredCard h2 {
  color:#ffffff;
}

.centeredCard h3 {
  color:#ffffff;
}

.centeredCard p {
  color:#ffffff;
}