.addProposers button {
    margin:10px;
}

.addProposers input {
    margin:2.5px;
}

.addProposers textarea {
    max-width: 500px;
    height:75px;
}

.addProposers #buttonPlus{
    background-color: #f48847;
    color:white;
    width: 100%;
    min-width: 50px;
    max-width: 50px; 
    font-size:18px;
    border-radius:12px;
    margin:2.5px;
    margin-top:10px;
    margin-bottom: 10px;
    }
    .addProposers #buttonPlus:hover,
    .addProposers #buttonPlus:active{
      background-color: #894b24;
      border-color: #000000;
}

.addProposers #actionButton{
    background-color: #f48847;
    color:white;
    width: 100%;
    min-width: 50px;
    max-width: 150px; 
    font-size:18px;
    border-radius:12px;
    margin:2.5px;
    margin-top:10px;
    margin-bottom: 10px;
    }
    .addProposers #actionButton:hover,
    .addProposers #actionButton:active{
      background-color: #894b24;
      border-color: #000000;
}