.circleRolesReader {
  background-color: #333333;
  border-radius: 12px;
  width:300px;
  height:50px;
  margin:auto;
  margin-top:5px;  
  text-align:center;
  
}
.circleRolesReader .header {
  margin-bottom: -20px;
}
/* 
.circleRolesReader h2 {
    padding: 5px;
    font-size: 15px;
    color:#ffffff;
    margin:0;
  }

  
.circleRolesReader p {
    padding: 5px;
    margin:0;
    padding:.1rem;
    color:#ffffff;
    font-size: 13px;
  } */