.attendeeCard {
    background-color: rgb(165, 93, 42);
    margin:auto;
    margin-bottom: 30px;
    min-width: 10px;
    max-width: 400px;
    text-align: center;
    border-radius: 12px;
}

.attendeeCard p {
    word-wrap: break-word;
    margin-bottom: -20px;
}