.logger {
  background-color: #333333;
  border-radius: 12px;
  
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 500px; 
  color:white;
}

.logger p {
  text-align:center;
  padding:.1rem;
  color:#ffffff;
  font-size: 18px;
}