/* #special {
    margin-bottom:-30px;
} */

.circles .specialDiv {
    background-color: aqua;

}

.circles .header {
   margin-bottom: -20px;
}