.connectWallet {
  background-color: #333333;
  border-radius: 12px;
  
  text-align: center;
  margin:auto;
  width: 100%;
  min-width: 50px;
  max-width: 250px; 
  color:white;
  }

.connectWallet button{
  text-align: center;
  background-color: #f48847;
  color:white;
  min-width: 50px;
  max-width: 125px; 
  font-size:24px;
  border-radius:12px;
  margin:2.5px;
  }
  .connectWallet button:hover,
  .connectWallet button:active{
    background-color: #894b24;
    border-color: #000000;
  }